<template>

  <!-- Start Content-->
  <div>

    <div class="d-flex justify-content-center p-5" v-if="isLoading">
      <div class="spinner-border m-12" role="status">
        <span class="sr-only"><div class="lang" v-html="lan.loading"></div>...</span>
      </div>
    </div>
    

    <div class="responsive-table-plugin" v-else-if="!jsonErr">

      <div class="table-rep-plugin">
        <div class="table-responsive" data-pattern="priority-columns">



          <table class="table table-striped table-borderless">
            <tbody>
             
              <tr v-for="(value, keyName) in jsonObj" :key="keyName">
                <th  v-if="(keyName!='reserved_item1' && keyName!='' && keyName!='max_evaluation'  && keyName!='reserved_item2' && keyName!='証券コード' && keyName!='reserved_item3' &&keyName!='reserved_item4' && viewParams.name=='corp_features') || viewParams.name!='corp_features'">
                 <div v-if="keyName=='Tax index'" class="assets_type"><img :src="'img/gudingzican.png'"></div>
                 <div v-if="keyName=='资产型(负债率)'" class="assets_type"><img :src="'img/asset_rate.png'"></div>
                 <div v-if="keyName=='流動比率'" class="assets_type"><img :src="'img/turn.png'"></div>
                 <div v-if="keyName=='証券コード' || keyName=='法人番号' || keyName=='会社名'" class="assets_type"><img :src="'img/card_name.png'"></div>
                 <div v-if="keyName=='短期業績（１ヶ月間の業績）' || keyName=='中期業績（半年の業績）' || keyName=='長期業績 1年間の業績'" class="assets_type"><img :src="'img/yeji.png'"></div>
                 <div v-if="keyName=='業種1' || keyName=='業種2' || keyName=='業種3' || keyName=='移転種類' " class="assets_type"><img :src="'img/category.png'"></div>
                 <div v-if="keyName=='移転歴' || keyName=='人材戦略タイプ' || keyName=='業界評判 Environmental, Social, and Governance' || keyName=='移転確率' "  class="assets_type"><img :src="'img/ok_rate.png'"></div>
                  <!-- {{ $language(keyName) }} -->
                  <span v-html="language(keyName)"></span>
                  <span v-if="['移転可能性','人材戦略(人数）','人材戦略(待遇)'].includes(keyName)">
                    <div class="ctooltip" @click="showPopupImg(keyName)"><i class="mdi mdi-help-circle-outline"></i>
                    
                   </div>
                    
                  </span>
                </th>
                <td v-if="(keyName!='reserved_item1' && keyName!='' && keyName!='max_evaluation' && keyName!='reserved_item2' && keyName!='証券コード' && keyName!='reserved_item3' &&keyName!='reserved_item4' && viewParams.name=='corp_features') || viewParams.name!='corp_features'"  v-bind:class="[max_evaluation==keyName ? 'active':'aa']">
                  <div v-if="keyName === 'reason'">
                    <div v-show="value">
                      <a :href="value" @click.prevent="isPopup = !isPopup" class="btn btn-white waves-effect dropdown-toggle px-3"><i class="mdi mdi-dock-window"></i></a>
                      <transition name="fade">
                        <div v-show="isPopup" class="modal popup-modal">
                          <div class="modal-dialog">
                            <div class="popup modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" @click="isPopup = false">
                                  <span>×</span>
                                </button>
                              </div>
                              <div class="modal-body embed-responsive embed-responsive-16by9" v-if="isPopup">
                                <iframe :src="value" class="embed-responsive-item"></iframe>
                              </div>
                            </div>
                          </div>
                          <div class="popup-bg" @click="isPopup = false"></div>
                        </div>
                      </transition>
                    
                    <a @click.prevent="isPopup2 = !isPopup2" v-if="viewParams.name === 'corp3'" class="btn btn-white waves-effect dropdown-toggle px-3 s3-pop"><i class="mdi mdi-dock-window"></i></a>
                      <transition name="fade" v-if="viewParams.name === 'corp3'">
                        <div v-show="isPopup2" class="modal popup-modal">
                          <div class="modal-dialog">
                            <div class="popup modal-content">
                              <div class="modal-header">
                                <button type="button" class="close" @click="isPopup2 = false">
                                  <span>×</span>
                                </button>
                              </div>
                              <div class="modal-body s3-img" v-if="isPopup2">
                                <img :src="'https://cbredatacom.s3-ap-northeast-1.amazonaws.com/market/stocks/corps/'+jsonObj.code+'.png'">
                              </div>
                            </div>
                          </div>
                          <div class="popup-bg" @click="isPopup = false"></div>
                        </div>
                      </transition>
                    </div>
                  </div>
                  <div v-else >
                    <div v-if="(keyName!='reserved_item1' && keyName!='reserved_item2' && keyName!='reserved_item3' &&keyName!='reserved_item4' && viewParams.name=='corp_features') || viewParams.name!='corp_features'">
                     <div v-if="keyName=='资产型(负债率)' || keyName=='流動比率' || keyName=='拡張（確率）' || keyName=='集約・調整' || keyName=='縮小（確率）' " >
                       {{value==null ? 0:(value*100).toFixed(2)+'%'}} 
                     </div>
                     <div v-else-if="keyName == 'ウェブサイト'">
                        <a :href="value" target="_black">{{value}}</a>
                      </div>
                      <div v-else-if="keyName == 'recruiting_attitude' || keyName == '人材戦略'">
                        <!-- <i :class="tagNames[value]" style="font-size: 22px;"></i> -->
                        <span>
                          {{ tagNames[value] }}
                        </span>
                      </div>
                     <div v-else v-html="value"></div>
                    </div>
                      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="modal-dialog" v-if="showModal">
            <div class="popup modal-content">
              <div class="modal-header">
                <button type="button" class="close" @click="showModal = false">
                  <span>×</span>
                </button>
              </div>
              <div class="modal-body w850">
                <div class="col-12">
                  <img class="popup-img-url" :src="popup_img_url" />
                </div>
              </div>
            </div>
          </div>

        </div> <!-- end .table-responsive -->
      </div> <!-- end .table-rep-plugin-->
      <div class="article-side" v-if="viewParams.name === 'build'">
        <google-map v-if="onMap" :buildCenter="{ lat: jsonObj.LatitudeMapCenter, lng: jsonObj.LongitudeMapCenter }" />
        <div class="row">
          <div class="col-12">
            <div class="card-box">
              <div class="mb-4" v-for="(obj, num) in rangeKeys" :key="num">
                <div v-if="jsonLang[lang][obj]">
                  <h4 class="header-title mb-2">{{ jsonLang[lang][obj] }}</h4>
                  <range-slider :id="obj" :from="extendParams[obj]" @updateRange="UpdateRange"/>
                </div>
              </div>
              <div class="mb-4">
                <div>
                  <h4 class="header-title mb-2">{{ jsonLang[lang]['memo'] }}</h4>
                  <textarea class="form-control" rows="10" v-model="extendParams.memo"></textarea>
                </div>
              </div>
              <div class="mb-2 alert alert-primary" v-if="saveAlert === 1"><div class="lang" v-html="lan.save_success"></div></div>
              <div class="mb-2 alert alert-danger" v-if="saveAlert === 9"><div class="lang" v-html="lan.save_false"></div></div>
              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-primary" @click="SaveParams"><div class="lang" v-html="lan.save"></div></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><!-- end .responsive-table-plugin-->
    <div v-else>
      <code>
      <div class="lang" v-html="lan.no_data"></div>
      </code>
    </div>
      
  </div> <!-- container -->

</template>

<script>
import axios from 'axios';
import { inject } from 'vue';
import GoogleMap from './GoogleMap.vue'
import RangeSlider from './RangeSlider.vue'
// import TagManager from './TagManager.vue'

export default {
  name: 'ListArticle',
  props: {
    viewParams: {
      name: {
        type: String,
        default: ''
      },
      apiUrl: {
        type: String,
        default: '',
      },
      paramKey: String,
    },
  },
  components: {
    GoogleMap,
    RangeSlider,
    // TagManager
  },
  data() {
    return {
      isChecked: [''],
      jsonObj: [Object],
      isFocus: -1,
      isDisplayAll: true,
      ddMenu: false,
      jsonErr: false,
      isLoading: true,
      onMap: false,
      isPopup: false, // PopupModal
      isPopup2: false, // PopupModal code img
      extendParams: {},
      rangeKeys: [],
      jsonLang: {},
      max_evaluation:'none',
      lang: 'ja', 
      showModal:false,
      saveAlert: 0, // 保存結果
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
      tagNames:[
        '',
        '積極採用',
        'やや積極',
        '前年並み',
        '消極採用',
        '情報不足'
      ],//1 好，2 中，3 差
      popup_img_url:''
    }
  },
  mounted() {
    if(this.viewParams.name === 'build') {
      this.jsonLang = require('@/assets/languages/ListArticle.json')
      axios
        .get(this.viewParams.apiUrl)
        .then(response => {
            this.jsonObj = response.data.data
            if(response.data.extended) {
              this.extendParams = response.data.extended
            } else {
              this.extendParams = {
                BuildingID: this.jsonObj.BuildingID,
                feature_1: null,
                feature_2: null,
                feature_3: null,
                feature_4: null,
                feature_5: null,
                feature_6: null,
                feature_7: null,
                feature_8: null,
                memo: ""
              }
              }
            this.RangeKeysFilter()
            this.isLoading = false
        })
        .catch(error => (
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          )
        );
    } else {
      axios
        .get(this.viewParams.apiUrl)
        .then(response => {
            this.jsonObj = response.data
            this.max_evaluation=response.data.max_evaluation==undefined ?'':response.data.max_evaluation;
            this.isLoading = false
        })
        .catch(error => (
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          )
        );
    }
  },
  methods: {
    language(v){
      return this.$language(v)
    },
    showPopupImg(keyName){
      this.showModal = true;
      
      if(keyName == '移転可能性'){
        this.popup_img_url = 'https://cbreai.s3.ap-northeast-1.amazonaws.com/corp_pred/shap/'+this.jsonObj['法人番号']+'/shap.png'
        return
      }else if(keyName == '人材戦略(人数）'){
        this.popup_img_url = 'https://cbreai.s3.ap-northeast-1.amazonaws.com/hr_num.png';
        return
        
      }else if(keyName == '人材戦略(待遇)'){
        this.popup_img_url = 'https://cbreai.s3.ap-northeast-1.amazonaws.com/hr_salary.png';
        return
      }
    },
    SaveParams: function() {
      axios
        .put(this.viewParams.apiUrl, {
          extended: this.extendParams
        })
        .then(() => {
          this.saveAlert = 1
        })
        .catch(error => {
          this.saveAlert = 9
          console.log(error)
        });
    },
    RangeKeysFilter: function() {
      let Ary = Object.keys(this.extendParams)
      //'feature_'からはじまるキー
      this.rangeKeys = Ary
      this.rangeKeys = Ary.filter( key => key.startsWith('feature_') )
    },
    UpdateRange(res) {
      this.extendParams[res.id] = res.data.from
    }
  },
  watch: {
    jsonObj(newjsonObj) {
      // 'address' flag indicator
      if( this.viewParams.name === 'build' ) {
        if( newjsonObj.address ) {
          this.onMap = true
        }
      }
    },
    isLoading: function() {
      if(!this.isLoading) {
        this.$nextTick(() => {
          window.parent.postMessage(['h'], "*")
        })
      }
    }
  }
}
</script>
<style lang="scss">
span .ctooltip{float: right !important;}
</style>
<style lang="scss" scoped>

.popup-img-url{
  height: 100%;
  width: 100%;
}

.responsive-table-plugin {
  display: flex;
  flex-wrap: wrap;
}

.table-rep-plugin {
  flex: 1;
}

@media screen and (max-width: 768px) {

  .responsive-table-plugin {
    flex-direction: column;
    // height: 90vh;
    width: 100%;
  }

  .table-responsive {
    height: 100%;
    max-height: none;
  }

}

.responsive-table-plugin tbody th {
  font-weight: bold;
  min-width: 12.5em;
  white-space: normal !important;
  width: 25%;
  word-break: break-all;
}

.responsive-table-plugin tbody td {
  white-space: normal !important;
  word-break: break-word;
}

.btn-back-fixed {
  position: fixed;
  right: 25px;
  top: 20px;
  z-index: 255;
}

.table-responsive .btn-white:hover {
  color: #fff;
  background-color: #003f2d;
  border-color: #003f2d;
}

.popup-modal {
  display: block;
}

.popup-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.popup {
  background: #fff;
  border-radius: .15rem;
  box-shadow: 0 .15rem .5rem rgba(0, 0, 0, .25);
  left: 50% !important;
  max-height: 85vh;
  max-width: 70%;
  position: fixed;
  top: 6.5rem !important;
  transform: translateX(-50%) !important;
  z-index: 16;
}

.popup iframe {
  width: 100%;
}

.fade-leave-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.article-side {
  width: 40%;
}

@media screen and (max-width: 768px) {

  .article-side {
    width: 100%;
  }

}
.s3-img,.s3-img img{max-width:100% !important;text-align: center;}
.s3-pop{margin-left:15px;}
.table tr{border-bottom: 1px solid #eee;}
.table th{background:#003f2d !important;color:#fff}
.active{background:#80BBAD !important;color:#fff}
.font-large-icon {
    font-size: 64px!important;
}
.assets_type{display:inline-block;    margin-right: 10px;}
.assets_type img{width:25px}

</style>