<template>
  <div>
    <headers />
    <div class="mb row" style="padding:15px;padding-top: 90px !important;">
      <div class="col-12">

        <!--<div class="page-title-box">
          <h4 class="page-title">企業詳細</h4>
        </div>-->
        <div class="tab-box" v-if="hideHead==0">
          <ul class="tab-head nav nav-tabs">
            <li
              v-if="code!=''"
              class="nav-item"
              data-id="tc1-2"
              @click="tab('tc1-2')"
              v-bind:class="[curindex=='tc1-2' ? 'nav-item active show':'nav-item']"
            >
              <a
                data-toggle="tab"
                v-bind:class="[curindex=='tc1-2' ? 'nav-link active':'nav-link']"
                aria-expanded="true"
              >
                <div class="lang" v-html="lan.profile"></div>
              </a>
            </li>
            <li
              class="nav-item"
              data-id="tc1"
              @click="tab('tc1')"
              v-bind:class="[curindex=='tc1' ? 'nav-item active show':'nav-item']"
            >
              <a
                data-toggle="tab"
                v-bind:class="[curindex=='tc1' ? 'nav-link active':'nav-link']"
                aria-expanded="true"
              >
                <div class="lang" v-html="lan.company_detail"></div>
              </a>
            </li>
           
            <li
              v-if="code!=''"
              v-bind:class="[curindex=='tc4' ? 'nav-item active show':'nav-item']"
              data-id="tc4"
              @click="tab('tc4')"
            >
              <a
                data-toggle="tab"
                aria-expanded="false"
                v-bind:class="[curindex=='tc4' ? 'nav-link active':'nav-link']"
              >
                <div class="lang" v-html="lan.pl"></div>
              </a>
            </li>
            <li
              v-if="code!=''"
              v-bind:class="[curindex=='tc2' ? 'nav-item active show':'nav-item']"
              data-id="tc2"
              @click="tab('tc2')"
            >
              <a
                data-toggle="tab"
                aria-expanded="false"
                v-bind:class="[curindex=='tc2' ? 'nav-link active':'nav-link']"
              >
                <div class="lang" v-html="lan.bs"></div>
              </a>
            </li>
            <li
              v-if="code!=''"
              v-bind:class="[curindex=='tc3' ? 'nav-item active show':'nav-item']"
              data-id="tc3"
              @click="tab('tc3')"
            >
              <a
                data-toggle="tab"
                aria-expanded="false"
                v-bind:class="[curindex=='tc3' ? 'nav-link active':'nav-link']"
              >
                <div class="lang" v-html="lan.cf"></div>
              </a>
            </li>
            <li
              v-if="code!=''"
              v-bind:class="[curindex=='tc5' ? 'nav-item active show':'nav-item']"
              data-id="tc5"
              @click="tab('tc5')"
            >
              <a
                data-toggle="tab"
                aria-expanded="false"
                v-bind:class="[curindex=='tc5' ? 'nav-link active':'nav-link']"
              >
                <div class="lang" v-html="lan.new_adoption"></div>
              </a>
            </li>
            <li
              v-if="code!=''"
              v-bind:class="[curindex=='tc51' ? 'nav-item active show':'nav-item']"
              data-id="tc51"
              @click="tab('tc51')"
            >
              <a
                data-toggle="tab"
                aria-expanded="false"
                v-bind:class="[curindex=='tc51' ? 'nav-link active':'nav-link']"
              >
                <div class="lang" v-html="lan.member_change"></div>
              </a>
            </li>
            <li
              v-if="code!=''"
              v-bind:class="[curindex=='tc6' ? 'nav-item active show':'nav-item']"
              data-id="tc6"
              @click="tab('tc6')"
            >
              <a
                data-toggle="tab"
                aria-expanded="false"
                v-bind:class="[curindex=='tc6' ? 'nav-link active':'nav-link']"
              >
                <div class="lang" v-html="lan.news"></div>
              </a>
            </li>
            <li
              v-bind:class="[curindex=='tc7' ? 'nav-item active show':'nav-item']"
              data-id="tc7"
              @click="tab('tc7')"
            >
              <a
                data-toggle="tab"
                aria-expanded="false"
                v-bind:class="[curindex=='tc7' ? 'nav-link active':'nav-link']"
              >
                <div class="lang" v-html="lan.past_business_career"></div>
              </a>
            </li>
            <li
              v-bind:class="[curindex=='tc8' ? 'nav-item active show':'nav-item']"
              data-id="tc8"
              @click="tab('tc8')"
            >
              <a
                data-toggle="tab"
                aria-expanded="false"
                v-bind:class="[curindex=='tc8' ? 'nav-link active':'nav-link']"
              >
                <div class="lang" v-html="lan.all_office"></div>
              </a>
            </li>
            <li
              v-if="code!=''"
              v-bind:class="[curindex=='tc9' ? 'nav-item active show':'nav-item']"
              data-id="tc9"
              @click="tab('tc9')"
            >
              <a
                data-toggle="tab"
                aria-expanded="false"
                v-bind:class="[curindex=='tc9' ? 'nav-link active':'nav-link']"
              >
                <div class="lang" v-html="lan.evaluation"></div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="tab-content" style="width:100%;background:#fff">
        <h4 class="header-title">{{ paramName }}</h4>
        <button
          class="btn btn-primary waves-effect waves-light btn-back-fixed"
          style="margin-right:110px"
          @click="fav()"
          v-if="curfav==1"
        >
          <div class="lang" v-html="lan.fav"></div>
        </button>
        <button
          class="btn btn-danger waves-effect waves-light btn-back-fixed"
          style="margin-right:110px"
          @click="unfav()"
          v-if="curfav==2"
        >
          <div class="lang" v-html="lan.unfav"></div>
        </button>
        <router-link :to="tolink" class="btn btn-primary waves-effect waves-light btn-back-fixed">
          <i class="mdi mdi-arrow-left-bold"></i> {{$language('一覧')}}
        </router-link>
        <div
          class="embed-responsive-16by9 tab-pane show active tc1"
          id="tc25"
          v-if="curindex=='tc1'"
        >
          <div class="col-12">
            <div class="card-box">
              <list-article :viewParams="viewParams" />
            </div>
          </div>
        </div>
        <div
          class="embed-responsive-16by9 tab-pane show active tc1-2"
          id="tc252"
          v-if="curindex=='tc1-2' && code!=''"
        >
          <div class="col-12">
            <div class="card-box">
              <list-article2 :viewParams="viewParamsinfo" />
            </div>
          </div>
        </div>
        <div
          class="embed-responsive-16by9 tab-pane tc2 active"
          id="tc26"
          v-if="curindex=='tc2' && code!=''"
        >
          <div class="col-12">
            <div class="card-box">
              <list-Corp-table :viewParams="viewParams11" />
            </div>
          </div>
        </div>
        <div
          class="embed-responsive-16by9 tab-pane tc3 active"
          id="tc27"
          v-if="curindex=='tc3' && code!=''"
        >
          <div class="col-12">
            <div class="card-box">
              <list-Corp-table :viewParams="viewParams12" />
            </div>
          </div>
        </div>
        <div
          class="embed-responsive-16by9 tab-pane tc4 active"
          id="tc28"
          v-if="curindex=='tc4' && code!=''"
        >
          <div class="col-12">
            <div class="card-box">
              <list-Corp-table :viewParams="viewParams13" />
            </div>
          </div>
        </div>
        <div
          class="embed-responsive-16by9 tab-pane show active tc5"
          v-if="curindex=='tc5' && code!=''"
        >
          <div class="col-12">
            <div class="card-box">
              <ul class="tab-head nav nav-tabs">
                <li
                  class="nav-item"
                  data-id="tc51"
                  @click="tab2('tc51')"
                  v-bind:class="[curindex2=='tc51' ? 'nav-item active show':'nav-item']"
                >
                  <a data-toggle="tab" class="nav-link" aria-expanded="true">
                    2021
                    <div class="lang" v-html="lan.new_recruitment_schedule"></div>
                  </a>
                </li>
                <li
                  v-if="code!=''"
                  class="nav-item"
                  data-id="tc52"
                  @click="tab2('tc52')"
                  v-bind:class="[curindex2=='tc52' ? 'nav-item active show':'nav-item']"
                >
                  <a data-toggle="tab" class="nav-link" aria-expanded="true">
                    2022
                    <div class="lang" v-html="lan.year"></div>
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div
                  class="embed-responsive-16by9 tab-pane tc51 active"
                  id="tc51"
                  v-if="curindex2=='tc51' && code!=''"
                >
                  <list-article2 :viewParams="viewParamsRecruit" />
                </div>
                <div
                  class="embed-responsive-16by9 tab-pane tc52 active"
                  id="tc52"
                  v-if="curindex2=='tc52' && code!=''"
                >
                  <list-article2 :viewParams="viewParamsFeaturesHr" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="embed-responsive-16by9 tab-pane tc51 active" id="tc51" v-if="curindex=='tc51'">
          <div class="col-12">
            <div class="card-box">
              <ListTableMemberNews :viewParams="viewParamsMemberNews" />
            </div>
          </div>
        </div>
        <div class="embed-responsive-16by9 tab-pane tc6 active" id="tc6" v-if="curindex=='tc6'">
          <div class="col-12">
            <div class="card-box">
              <ListTableNews :viewParams="viewParamsNews" />
            </div>
          </div>
        </div>
        <div class="embed-responsive-16by9 tab-pane tc7 active" id="tc7" v-if="curindex=='tc7'">
          <div class="col-12">
            <div class="card-box">
              <list-Corp-table :viewParams="viewParamsHistory" />
            </div>
          </div>
        </div>
        <div class="embed-responsive-16by9 tab-pane tc9 active" id="tc8" v-if="curindex=='tc8'">
          <div class="col-12">
            <div class="card-box">
              <list-Corp-table :viewParams="viewParamsOffice" />
            </div> 
          </div>
        </div>
        <div
          class="embed-responsive-16by9 tab-pane show active tc9"
          id="tc9"
          v-if="curindex=='tc9' && code!=''"
        >
          <div class="col-12">
            <div class="card-box">
              <list-article2 :viewParams="viewParamsFeatures" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListArticle from "@/components/ListArticle.vue";
import ListArticle2 from "@/components/ListArticle2.vue";
import ListCorpTable from "@/components/ListCorpTable.vue";
import ListTableNews from "@/components/ListTableNews.vue";
import ListTableMemberNews from "@/components/ListTableMemberNews.vue";
import router from "@/router";
import axios from "axios";
import { inject } from "vue";
import Headers from "@/components/Headers.vue";
export default {
  name: "CorpDetail",
  components: {
    ListArticle,
    ListCorpTable,
    ListArticle2,
    ListTableNews,
    ListTableMemberNews,
    Headers,
  },
  data() {
    const { currentRoute } = router;
    return {
      curindex:
        currentRoute.value.query.curindex == undefined ||
        currentRoute.value.query.curindex == null ||
        currentRoute.value.query.curindex == "" ||
        currentRoute.value.query.curindex == "tc1"
          ? "tc1-2"
          : currentRoute.value.query.curindex,
      curindex2: "tc51",
      curfav: 0,
      cur_lan: "jp",
      lan: inject("global").language_data,
      lan_help: inject("global").language_help_data,
      tolink: ("/" + currentRoute.value.query.tolink).replace("//", "/"),
      paramName: currentRoute.value.query.dataName,
      dataType: currentRoute.value.query.dataType,
      dataId: currentRoute.value.query.dataId,
      code: currentRoute.value.query.code,
      hideHead:
        currentRoute.value.query.hideHead == undefined
          ? 0
          : currentRoute.value.query.hideHead,
      dataNumber:
        currentRoute.value.query.dataNumber == undefined
          ? ""
          : currentRoute.value.query.dataNumber,
      viewParams: {
        name: "corp" + currentRoute.value.query.dataType,
        apiUrl:
          "/v1/corps" +
          currentRoute.value.query.dataType +
          "/" +
          currentRoute.value.query.dataId +
          "?cid=" +
          currentRoute.value.query.dataType +
          "&code=" +
          currentRoute.value.query.code +
          "&corp_name=" +
          currentRoute.value.query.dataName,
        paramKey: "Corp_Name",
      },
      viewParams11: {
        name: "corp_BS",
        apiUrl:
          "/v1/corps-nosql11/" +
          currentRoute.value.query.dataId +
          "?cid=" +
          currentRoute.value.query.dataType +
          "&code=" +
          currentRoute.value.query.code,
        paramKey: "ID",
      },
      viewParams12: {
        name: "corp_CF",
        apiUrl:
          "/v1/corps-nosql12/" +
          currentRoute.value.query.dataId +
          "?cid=" +
          currentRoute.value.query.dataType +
          "&code=" +
          currentRoute.value.query.code,
        paramKey: "ID",
      },
      viewParams13: {
        name: "corp_PL",
        apiUrl:
          "/v1/corps-nosql13/" +
          currentRoute.value.query.dataId +
          "?cid=" +
          currentRoute.value.query.dataType +
          "&code=" +
          currentRoute.value.query.code,
        paramKey: "ID",
      },
      viewParamsOffice: {
        name: "corp_offices",
        apiUrl:
          "/v1/corpsoffices/" +
          currentRoute.value.query.dataId +
          "?cid=" +
          currentRoute.value.query.dataType +
          "&code=" +
          currentRoute.value.query.code +
          "&corp_name=" +
          currentRoute.value.query.dataName +
          "&corp_num=",
        paramKey: "ID",
        randIndex:
          currentRoute.value.query.hideHead == undefined
            ? 0
            : currentRoute.value.query.hideHead,
      },
      viewParamsHistory: {
        name: "corp_history",
        apiUrl:
          "/v1/getListByCropNum?cropNum=" + currentRoute.value.query.dataNumber,
        paramKey: "ID",
      },
      viewParamsNews: {
        name: "corp_news",
        apiUrl: "/v1/corp_news_list?code=",
        paramKey: "ID",
        articleParamId: "id",
        randIndex: 0,
      },
      viewParamsMemberNews: {
        name: "corp_news_member",
        apiUrl: "/v1/corp_news_member_list?code=",
        paramKey: "ID",
        articleParamId: "id",
        randIndex: 0,
      },
      viewParamsinfo: {
        name: "corp_info",
        apiUrl:
          "/v1/corps-nosqlinfo/" +
          currentRoute.value.query.dataId +
          "?cid=" +
          currentRoute.value.query.dataType +
          "&code=" +
          currentRoute.value.query.code,
        paramKey: "ID",
      },
      viewParamsRecruit: {
        name: "corp_info",
        apiUrl:
          "/v1/corpsrecruit/" +
          currentRoute.value.query.dataId +
          "?cid=" +
          currentRoute.value.query.dataType +
          "&code=" +
          currentRoute.value.query.code,
        paramKey: "ID",
      },
      viewParamsFeaturesHr: {
        name: "corp_recruit_hr",
        apiUrl:
          "/v1/corpsfeatureshr/" +
          currentRoute.value.query.dataId +
          "?cid=" +
          currentRoute.value.query.dataType +
          "&code=" +
          currentRoute.value.query.code,
        paramKey: "ID",
      },
      viewParamsFeatures: {
        name: "corp_features",
        apiUrl:
          "/v1/corpsfeatures/" +
          currentRoute.value.query.dataId +
          "?cid=" +
          currentRoute.value.query.dataType +
          "&code=" +
          currentRoute.value.query.code,
        paramKey: "ID",
      },
    };
  },
  methods: {
    tab: function (tid) {
      this.curindex = tid;
      this.$router.push(
        (this.dataType == 100
          ? "/CorpReference/0"
          : "/corp" + this.dataType + "/" + this.dataId) +
          "?dataId=" +
          this.dataId +
          "&dataType=" +
          this.dataType +
          "&dataName=" +
          this.paramName +
          "&dataNumber=" +
          this.dataNumber +
          "&code=" +
          this.code +
          "&curindex=" +
          tid
      );
    },
    tab2: function (tid) {
      this.curindex2 = tid;
    },
    backListener() {
      this.$refs.myEnergy.destroySelf();
    },
    fav() {
      axios
        .get(
          "/v1/cbreai_user_corp_add?code=" +
            this.code +
            "&corp_name=" +
            this.paramName +
            "&corp_number=" +
            this.dataNumber +
            "&email=" +
            localStorage.getItem("email"),
          { params: { id_token: localStorage.getItem("id_token") } }
        )
        .then((response) => {
          this.curfav = 2;
          console.log(response.data);
        })
        .catch((error) => console.log(error));
    },
    unfav() {
      axios
        .get(
          "/v1/cbreai_user_corp_remove?code=" +
            this.code +
            "&corp_number=" +
            this.dataNumber +
            "&email=" +
            localStorage.getItem("email"),
          { params: { id_token: localStorage.getItem("id_token") } }
        )
        .then((response) => {
          console.log(response.data);
          this.curfav = 1;
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    //let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data;
    console.log(this.tolink);
    //挂载完成后，判断浏览器是否支持popstate
    if (window.history && window.history.pushState) {
      window.addEventListener("pagehide", this.backListener, false);
    }
    if (this.dataType != "") {
      localStorage.setItem("homeindex", this.tolink);
    }
    if (this.hideHead == 1) {
      this.curindex = "tc8";
    }
    if (this.dataType == 100) {
      axios
        .get(
          "/v1/corps" +
            this.dataType +
            "/" +
            this.dataId +
            "?cid=" +
            this.dataType +
            "&code=" +
            this.code +
            "&corp_name=" +
            this.paramName,
          {
            params: {
              id_token: localStorage.getItem("id_token"),
              email: localStorage.getItem("email"),
            },
          }
        )
        .then((response) => {
          this.curfav = response.data.isfav;
          this.dataId = response.data.ID;
          this.code = response.data.code != undefined ? response.data.code : "";
          this.dataNumber =
            response.data.Corp_Num != undefined
              ? response.data.Corp_Num
              : response.data.corp_num;
          this.viewParamsHistory.apiUrl =
            "/v1/getListByCropNum?cropNum=" + this.dataNumber;
          this.viewParamsNews.apiUrl = "/v1/corp_news_list?code=" + this.code;
          this.viewParamsMemberNews.apiUrl =
            "/v1/corp_news_member_list?code=" + this.code;
          this.viewParamsOffice.apiUrl =
            "/v1/corpsoffices/" +
            this.dataId +
            "?cid=" +
            this.dataType +
            "&code=" +
            this.code +
            "&corp_name=" +
            this.paramName +
            "&corp_num=" +
            this.dataNumber;
          this.viewParams.apiUrl =
            "/v1/corps" +
            this.dataType +
            "/" +
            this.dataId +
            "?cid=" +
            this.dataType +
            "&code=" +
            this.code +
            "&corp_name=" +
            this.paramName;
          this.viewParams11.apiUrl =
            "/v1/corps-nosql11/" +
            this.dataId +
            "?cid=" +
            this.dataType +
            "&code=" +
            this.code;
          this.viewParams12.apiUrl =
            "/v1/corps-nosql12/" +
            this.dataId +
            "?cid=" +
            this.dataType +
            "&code=" +
            this.code;
          this.viewParams13.apiUrl =
            "/v1/corps-nosql13/" +
            this.dataId +
            "?cid=" +
            this.dataType +
            "&code=" +
            this.code;
          this.viewParamsinfo.apiUrl =
            "/v1/corps-nosqlinfo/" +
            this.dataId +
            "?cid=" +
            this.dataType +
            "&code=" +
            this.code;
          this.viewParamsRecruit.apiUrl =
            "/v1/corpsrecruit/" +
            this.dataId +
            "?cid=" +
            this.dataType +
            "&code=" +
            this.code;
          this.viewParamsFeatures.apiUrl =
            "/v1/corpsfeatures/" +
            this.dataId +
            "?cid=" +
            this.dataType +
            "&code=" +
            this.code;
        })
        .catch(
          (error) => (
            console.log(error), (this.jsonErr = true), (this.isLoading = false)
          )
        );
    }
  },
  beforeUnmount() {
    window.removeEventListener("pagehide", this.backListener);
  },
};
</script>
